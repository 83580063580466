import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Layout } from '../components/layout/Layout'
import { SiteHeader } from '../components/site-header/SiteHeader'
import { SiteFooter } from '../components/site-footer/SiteFooter'
import { HeadingMd } from '../components/heading/Heading'
import { TextBanner } from '../components/text-banner/TextBanner'
import { Hero } from '../components/hero/Hero'
import { Faq } from '../components/faq/Faq'

const BehandelingenPage = () => {
  return (
    <Layout title="Veelgestelde vragen" desc="Wij beantwoorden de meest gestelde vragen over uw mondgezondheid en uw bezoek aan de mondhygienist">
      <SiteHeader />

      <Hero
        image={
          <StaticImage
            src="../images/alex-padurariu-7ByfI6Fpi90-unsplash.jpg"
            placeholder="blurred"
            alt=""
            loading="eager"
            formats={['auto', 'webp', 'avif']}
          />
        }
        title="Veelgestelde vragen"
      />

      <TextBanner>
        <HeadingMd h={2}>Antwoord op uw vragen</HeadingMd>
        <Faq
          title="Wat zijn de tarieven van de mondhygieniste"
          text="De tarieven zijn comform de <a href='https://www.nza.nl/' target='_blank'>Nederlandse zorgautoriteit</a>"
        />
        <Faq
          title="Kan ik ingeschreven blijven bij mijn huidige tandarts, terwijl ik daarnaast de mondhygiënist met een eigen praktijk bezoek voor preventief onderhoud?"
          text="Jazeker, dan kan heel goed. Functiescheiding biedt u grote voordelen. De preventieve behandeling wordt uitgevoerd door een specialist, de erkend mondhygiënist. Eventuele gebitsproblemen worden behandeld door de tandarts. Zo heeft u op ieder gebied de beste zorgverlener tot uw beschikking."
        />
        <Faq
          title="Wat is het verschil tussen een (preventie) assistente bij de tandarts en een erkend mondhygiënist?"
          text="Als onafhankelijk mondhygiënist kan ik mij volledig toewijden aan preventie en de behandeling van tandvleesproblemen. Ik doe geen behandelingen als gaatjes vullen of kronen en bruggen maken. Ik heb één belang en dat is samen met jou zorgen voor een gezonde mond."
        />
        <Faq
          title="Heb ik een verwijzing nodig van mijn tandarts?"
          text="Nee dat hoeft niet. Het staat u helemaal vrij een afspraak te maken met de mondhygiënist. In mijn praktijk geef ik de beste zorg op basis van kennis én uw zorgwensen."
        />
        <Faq
          title="Hoe herken ik ontstoken tanddvlees?"
          text="De kleur van gezond tandvlees is roze. Ongezond of ontstoken tandvlees is vaak roder van kleur, gezwollen en bloedt sneller bij bijvoorbeeld tandenpoetsen. Er is een regel die altijd geldt: gezond tandvlees bloedt nooit! Het eerste stadium van ontstoken tandvlees wordt gingivitis genoemd, een verder gevorderd stadium noemen we parodontitis."
        />
        <Faq
          title="Hoe ontstaat ontstoken tandvlees?"
          text="De ontsteking in de rand van het tandvlees wordt veroorzaakt door bacteriën in de tandplak. Tandplak is een zacht en kleverig, nauwelijks zichtbaar laagje van speekseleiwitten en bacteriemassa op de tanden en kiezen. Tandplak kan verkalken tot tandsteen dat stevig vastzit aan de tanden en kiezen. Dit proces herhaalt zich."
        />
      </TextBanner>

      <SiteFooter />
    </Layout>
  )
}

export default BehandelingenPage
