import * as React from 'react'

import { HeadingLg, HeadingMd } from '../heading/Heading'
import { ButtonSecondary } from '../button/Button'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import * as styles from './Hero.module.css'

export function Hero({ title, text = undefined, button = undefined, image }) {
  const breakpoints = useBreakpoint()
  return (
    <div className={styles.hero}>
      <article className={styles.content}>
        <header className={styles.heading}>
          {breakpoints.sm ? <HeadingMd h={1}>{title}</HeadingMd> : <HeadingLg h={1}>{title}</HeadingLg>}
        </header>
        {text && <p className={styles.text}>{text}</p>}
        {button && <ButtonSecondary url={button.url}>{button.title}</ButtonSecondary>}
      </article>
      {image && <div className={styles.image}>{image}</div>}
    </div>
  )
}
