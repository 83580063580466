import cx from 'classnames'
import * as React from 'react'
import * as styles from './Button.module.css'
import { Link } from "gatsby"

export function ButtonPrimary({
  onClick = undefined,
  url = undefined,
  children,
  target = undefined,
  rel = undefined,
  type = 'button',
}) {
  return url ? (
    <ABase
      className={styles.buttonPrimary}
      {...{ onClick, type, children, url, target, rel }}
    />
  ) : (
    <ButtonBase
      className={styles.buttonPrimary}
      {...{ onClick, type, children }}
    />
  )
}

export function ButtonSecondary({
  onClick = undefined,
  url = undefined,
  children,
  target = undefined,
  rel = undefined,
  type = 'button',
}) {
  return url ? (
    <ABase
      className={styles.buttonSecondary}
      {...{ onClick, type, children, url, target, rel }}
    />
  ) : (
    <ButtonBase
      className={styles.buttonSecondary}
      {...{ onClick, type, children }}
    />
  )
}

export function ButtonTertiary({
  onClick = undefined,
  url = undefined,
  children,
  target = undefined,
  rel = undefined,
  type = 'button',
}) {
  return url ? (
    <ABase
      className={styles.buttonTertiary}
      {...{ onClick, type, children, url, target, rel }}
    />
  ) : (
    <ButtonBase
      className={styles.buttonTertiary}
      {...{ onClick, type, children }}
    />
  )
}

export function ButtonGhost({
  onClick = undefined,
  url = undefined,
  children,
  target = undefined,
  rel = undefined,
  type = 'button',
}) {
  return url ? (
    <ABase
      className={styles.buttonGhost}
      {...{ onClick, type, children, url, target, rel }}
    />
  ) : (
    <ButtonBase
      className={styles.buttonGhost}
      {...{ onClick, type, children }}
    />
  )
}


function ButtonBase({ className, onClick, type, disabled = undefined, children }) {
  return (
    <button className={cx(styles.button, className)} {...{ onClick, type, disabled, children }}>
      {children}
    </button>
  )
}

function ABase({ url, onClick, children, className, target, rel: initalRel, disabled = undefined }) {
  const rel = target === '_blank' ? (initalRel || '') + ' noopener noreferrer' : initalRel

  return rel ? (
    <a className={cx(styles.button, className)} href={disabled ? undefined : url} {...{ onClick, children, target, rel }}>
      {children}
    </a>
  ) : (
    <Link className={cx(styles.button, className)} to={url}>
      {children}
    </Link>
  )
}
